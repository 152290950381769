<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="商品id：">
            <el-input v-model="search_id" placeholder="请输入商品id"></el-input>
        </el-form-item>
        <el-form-item label="商品标题：">
          <el-input v-model="search_title" placeholder="请输入商品标题"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="search_status" placeholder="">
            <el-option label="全部" value=""></el-option>
            <el-option label="上架" value="1"></el-option>
            <el-option label="下架" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="位置：">
          <el-select v-model="search_location" placeholder="">
            <el-option v-for="item in locationList" :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="seach(1)">搜索</el-button>
            <el-button type="" @click="reset(1)">重置</el-button>
        </el-form-item>
    </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加商品</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
      v-loading="loading"
      :data="choiceGoodsList"
      border
      style="width: 100%;">
      <el-table-column fixed label="ID" prop="id" width="50"></el-table-column>
      <el-table-column fixed label="商品ID" prop="item_id" width="120"></el-table-column>
      <el-table-column label="平台" prop="platform">
        <template slot-scope="item">
          {{item.row.platform | platform}}
        </template>
</el-table-column>
<el-table-column label="标题" prop="item_title" width="250"></el-table-column>
<el-table-column label="主图" prop="item_id">
    <template slot-scope="scope">
        <el-image :src="scope.row.item_pic" :preview-src-list="[scope.row.item_pic]"></el-image>
    </template>
</el-table-column>
<el-table-column label="券后价" prop="end_price" width="100"></el-table-column>
<el-table-column label="优惠券" prop="coupon_amount" width="100"></el-table-column>
<el-table-column label="佣金比例" prop="commission_rate" width="80"></el-table-column>
<el-table-column label="位置" prop="location_arr" width="150">
    <template slot-scope="item">
    {{item.row.location_arr | location}}
  </template>
</el-table-column>
<el-table-column label="权重" prop="weights" width="50"></el-table-column>
<el-table-column label="修改时间" prop="update_time" width="170">
    <template slot-scope="item">
      {{item.row.update_time | dateTime}}
    </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
      <el-button type="primary" size="mini" @click="getChoiceGoodsDetail(item.row.id)">编辑</el-button>
      <el-button :type="item.row.status==1?'danger':'primary'" size="mini" @click="soldOut(item.row.id,item.row.status)">{{item.row.status==1?'下架':'上架'}}</el-button>
    </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<choiceDialog :showExport="show" @clickClose="close" :platform="goodsDetail"></choiceDialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'choiceGoods',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                choiceGoodsList: [],
                locationList: [...[{
                    name: '全部',
                    type: ''
                }], ...common.item_position_list],

                search_id: '',
                search_title: '',
                search_status: '1',
                search_location: '',

                show: false,
                goodsDetail: {},

            }
        },
        filters: {
            location(val) {
                let name = ''
                val.some(item => {
                    name = name + item.location_name + '、'
                })
                return name
            },
            platform(val) {
                let name = ''
                if (JSON.stringify(common.item_list_platform).indexOf(val) != -1) {
                    common.item_list_platform.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getChoiceGoods()
        },
        methods: {
            // 获取列表
            getChoiceGoods(page, size) {
                api.getChoiceGoodsList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    item_id: this.search_id,
                    item_title: this.search_title,
                    status: this.search_status,
                    location_type: this.search_location
                }, res => {
                    this.total = Number(res.data.count)
                    this.choiceGoodsList = res.data.list
                    this.loading = false
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getChoiceGoods()
            },
            currentChange(val) {
                this.page = val
                this.getChoiceGoods()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getChoiceGoods(page)
            },
            reset(page) {
                this.search_id = ''
                this.search_title = ''
                this.search_status = '1'
                this.search_location = ''
                this.page = page
                this.getChoiceGoods(page)
            },
            // 上下架
            soldOut(id, status) {
                if (status == 1) {
                    this.$confirm('下架后用户不可见，你还要继续吗', '确定要下架商品吗？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.updateStatus(id, status)
                    }).catch(() => {})
                    return
                }
                this.updateStatus(id, status)
            },
            updateStatus(id, status) {
                api.updateChoiceGoodsStatus({
                    id: id,
                    status: status == 1 ? 2 : 1
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getChoiceGoods()
                })
            },
            // 关闭弹窗
            close() {
                this.show = false
                this.getChoiceGoods()
            },
            // 获取选品详情
            getChoiceGoodsDetail(id) {
                api.getChoiceGoodsDetail({
                    id: id
                }, res => {
                    this.goodsDetail = res.data
                    this.show = true
                })
            },
            // 添加选品
            add() {
                this.goodsDetail = {}
                this.show = true
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    /* img {
        height: 40px;
    } */
</style>